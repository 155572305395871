var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._m(0), _c('div', {
    staticClass: "mt-4 bg-white rounded-lg px-4 py-3 w-full flex justify-start items-center shadow-sm"
  }, [_c('div', {
    staticClass: "mr-2 flex justify-start items-center"
  }, [_c('div', {
    class: {
      'rounded-full border-custom border-blue-500 w-7 h-7 p-1 justify-center text-sm flex font-semibold text-blue-500': !_vm.nextSection,
      'rounded-full w-7 h-7 border p-1 justify-center text-sm flex': _vm.nextSection
    }
  }, [_vm._v(" 1 ")]), _c('span', {
    class: {
      'ml-3 text-blue-500 font-semibold': !_vm.nextSection,
      'ml-3 text-gray-500 font-semibold': _vm.nextSection
    }
  }, [_vm._v("Bulk Update")])]), _c('div', {
    staticClass: "mr-2"
  }, [_c('svg', {
    staticClass: "w-6 h-6 text-gray-500 dark:text-white",
    attrs: {
      "aria-hidden": "true",
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "viewBox": "0 0 24 24"
    }
  }, [_c('path', {
    attrs: {
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "d": "m7 16 4-4-4-4m6 8 4-4-4-4"
    }
  })])]), _c('div', {
    staticClass: "mr-3 flex justify-start items-center"
  }, [_c('div', {
    class: {
      'rounded-full border-custom border-blue-500 w-7 h-7 p-1 justify-center text-sm flex font-semibold text-blue-500': _vm.nextSection,
      'rounded-full w-7 h-7 border p-1 justify-center text-sm flex': !_vm.nextSection
    }
  }, [_vm._v("2")]), _c('span', {
    class: {
      'ml-3 text-blue-500 font-semibold': _vm.nextSection,
      'ml-3 text-gray-500 font-semibold': !_vm.nextSection
    }
  }, [_vm._v("Preview")])]), _c('span')]), !_vm.nextSection ? _c('div', {
    staticClass: "bg-white my-3 py-3 rounded-lg px-4 shadow-sm"
  }, [_c('div', {
    staticClass: "w-full flex justify-between"
  }, [_c('div', {
    staticClass: "w-1/2 pr-10"
  }, [_c('div', {
    staticClass: "block pr-2"
  }, [_c('label', {
    staticClass: "font-semibold text-gray-600"
  }, [_vm._v("Brand Name")]), _c('multiselect', {
    staticClass: "w-full mr-4",
    attrs: {
      "label": "name",
      "track-by": "name",
      "placeholder": "Enter Brand",
      "open-direction": "bottom",
      "required": "required",
      "options": _vm.brandSearchOptions,
      "searchable": true,
      "loading": _vm.isBrandSearch,
      "close-on-select": true,
      "options-limit": 10,
      "multiple": true,
      "allow-empty": false
    },
    on: {
      "search-change": _vm.brandFind,
      "select": _vm.brandSelected
    },
    model: {
      value: _vm.form.brand,
      callback: function ($$v) {
        _vm.$set(_vm.form, "brand", $$v);
      },
      expression: "form.brand"
    }
  })], 1), _c('div', {
    staticClass: "block"
  }, [_c('label', {
    staticClass: "font-semibold text-gray-600 mt-3"
  }, [_vm._v("Release Date")]), _c('div', {
    staticClass: "grid gap-4 grid-cols-2 mt-0 mb-3"
  }, [_c('div', {
    staticClass: "block w-full"
  }, [_c('DatetimeCustom', {
    attrs: {
      "timeDate": false,
      "label": 'Start Date'
    },
    model: {
      value: _vm.form.release_date,
      callback: function ($$v) {
        _vm.$set(_vm.form, "release_date", $$v);
      },
      expression: "form.release_date"
    }
  })], 1), _c('div', {
    staticClass: "block w-full"
  }, [_c('DatetimeCustom', {
    attrs: {
      "timeDate": false,
      "label": 'End Date'
    },
    model: {
      value: _vm.form.release_schedule,
      callback: function ($$v) {
        _vm.$set(_vm.form, "release_schedule", $$v);
      },
      expression: "form.release_schedule"
    }
  })], 1)])]), _c('div', {
    staticClass: "grid gap-4 grid-cols-2 mt-3 mb-3"
  }, [_c('div', {
    staticClass: "mt-0"
  }, [_c('label', {
    staticClass: "font-semibold text-gray-600 mb-3"
  }, [_vm._v("Printed Price Currency (Optional)")]), _c('t-select', {
    attrs: {
      "id": "currency",
      "options": _vm.currencys,
      "required": ""
    },
    model: {
      value: _vm.form.currency,
      callback: function ($$v) {
        _vm.$set(_vm.form, "currency", $$v);
      },
      expression: "form.currency"
    }
  })], 1), _c('div', {
    staticClass: "mt-0"
  }, [_c('label', {
    staticClass: "font-semibold mb-3 text-gray-600"
  }, [_vm._v("Printed Price (Optional)")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.price,
      expression: "form.price"
    }],
    staticClass: "w-full bg-gray-50 rounded-lg border",
    attrs: {
      "id": "price",
      "type": "number",
      "step": "any",
      "placeholder": "99000 (no sparator)",
      "required": ""
    },
    domProps: {
      "value": _vm.form.price
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "price", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "flex w-full justify-start mb-3"
  }, [_c('button', {
    staticClass: "bg-blue-700 text-white py-2 px-3 rounded-lg mt-3 mr-2",
    class: this.form.brand.length === 0 ? 'opacity-50' : '',
    attrs: {
      "disabled": this.form.brand.length === 0
    },
    on: {
      "click": _vm.handleNextClick
    }
  }, [_vm._v(" Next ")]), _c('button', {
    staticClass: "bg-white border py-2 px-3 rounded-lg mt-3 mr-2",
    on: {
      "click": _vm.handleCancel
    }
  }, [_vm._v("Cancel")])])])])]) : _vm._e(), _vm.nextSection ? _c('div', {
    staticClass: "bg-white my-3 py-3 rounded-lg px-4 shadow-sm"
  }, [_c('tableNewspaperBulky', {
    attrs: {
      "selectOnDataTable": this.form.brand,
      "start_date": this.form.release_date,
      "end_date": this.form.release_schedule,
      "price": this.form.price,
      "currency": this.form.currency,
      "nextSection": this.nextSection
    },
    on: {
      "update:nextSection": function ($event) {
        _vm.nextSection = $event;
      }
    }
  })], 1) : _vm._e()]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "bg-white p-4 text-gray-500 my-4 rounded-lg shadow-sm"
  }, [_c('span', {
    staticClass: "py-3 text-gray-500"
  }, [_vm._v(" Newspapers"), _c('i', {
    staticClass: "fa fa-angle-right ml-2",
    attrs: {
      "aria-hidden": "true"
    }
  }), _vm._v(" Bulk Edit Printed Price "), _c('h5', {
    staticClass: "mt-1 text-xl font-semibold text-gray-700"
  }, [_vm._v("Bulk Edit Printed Price")])])]);
}]

export { render, staticRenderFns }