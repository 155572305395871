<template>
  <div class="bg-white p-0 rounded-lg">
    <h3 class="text-lg font-semibold text-gray-800 mb-4">Bulk Edit Printed Price Details</h3>
    <div class="border rounded-lg">
      <table class="min-w-full divide-y divide-gray-200">
        <thead class="bg-gray-50">
          <tr>
            <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Act</th>
            <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">No</th>
            <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Brand Name</th>
            <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Published Start Date</th>
            <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Published End Date</th>
            <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Currency</th>
            <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Printed Price</th>
          </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-200">
          <tr>
            <td></td>
            <td></td>
            <td colspan="1" class="text-center py-3 pl-2 px-0">
              <div class="flex justify-end w-full">
                  <div class="block w-full">
                    <button @click="addNewRow" class="text-center border-custom bg-white w-full text-blue-500 font-semibold px-0 py-2 rounded-lg">
                      <i class="fa-solid fa-plus mr-2"></i> Add New
                    </button>
                  </div>
              </div>
            </td>
          </tr>
          <tr  v-for="(item, index) in selectOnDataTable" :key="item.id">
            <td class="px-6 py-0 text-center whitespace-nowrap"><i class="fa-solid fa-trash text-gray-500 cursor-pointer" @click="deleteRow(index)"></i></td>
            <td class="px-6 py-0 text-center whitespace-nowrap">{{ index + 1 }}</td>
            <td class="px-6 py-0 whitespace-nowrap">
              <div class="mt-3 mb-3">
                <multiselect 
                v-model="selectOnDataTable[index]" 
                label="name" 
                track-by="name" 
                placeholder="Enter Brand"
                class="w-full"
                open-direction="bottom"
                :options="brandSearchOptions"
                :searchable="true"
                :loading="isBrandSearch"
                :close-on-select="true"
                :options-limit="10"
                :multiple="false"
                :allow-empty="false"
                @search-change="brandFind"
              />
              </div>
              
            </td>
            <td class="px-6 py-0 whitespace-nowrap">
              <div class="mb-3">
                <DatetimeCustom :timeDate="false" :label="''" v-model="getStartDate[index]" />
              </div>
            </td>
            <td class="px-6 py-0 whitespace-nowrap">
              <div class="mb-3">
                <DatetimeCustom :timeDate="false" :label="''" v-model="getEndDate[index]" />
              </div>
            </td>
            <td class="px-6 py-0 whitespace-nowrap">
              <div class="mt-3 mb-3">
                <t-select id="currency" v-model="getCurrency[index]" :options="currencys" required />
              </div>
            </td>
            <td class="px-6 py-0 whitespace-nowrap">
              <div class="mt-3 mb-3">
                <input type="number" v-model="getPrice[index]" class="w-full border rounded px-3 py-2 focus:ring focus:ring-blue-200" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div>
      <div class="flex justify-start mb-3">
      <button class="mt-4 bg-blue-700 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-blue-300"
            @click="onSubmitData">Submit
    </button>
    <button class="py-2 mt-4 px-4 border ml-2 rounded-lg cursor-pointer" @click="handleCancel">Cancel</button>
  
    </div>
    </div>
  
  </div>
</template>

<style scoped>
</style>

<script>
import DatetimeCustom from "../../../components/form/Datetime.vue"
import { mapActions } from 'vuex';
import constant from "../../../store/constant";
import moment from 'moment';
const PRINTED_PRICE = Object.values(constant.PRINTED_PRICE_ITEM);

export default {
  components: {
    DatetimeCustom,
  },
  props: {
    selectOnDataTable: {
      type: Array,
      required: true
    },
    start_date: {
      type: String,
      required: true
    },
    end_date: {
      type: String,
      required: true
    },
    price: {
      type: String,
      required: true
    },
    currency: {
      type: String,
      required: true
    },
    nextSection: {
      type:Boolean,
      required: true
    }
  },
  data() {
    return {
      currencys: [...PRINTED_PRICE],
      getPrice: [],
      getData: [],
      getStartDate: [],
      getEndDate: [],
      getCurrency: [],
      isBrandSearch: false,
      debounce: null,
      brandSearchOptions: [],
    }
  },
  created() {
  },
  watch: {
    selectOnDataTable: {
      immediate: true,
      handler(data) {
        this.getPrice = data.map(() => this.price || 0);
        this.getData = data.map(item => ({ name: item.name,id:item.id }));
        this.getStartDate = data.map(() => this.start_date);
        this.getEndDate = data.map(() => this.end_date);
        this.getCurrency = data.map(() => this.currency);
      }
    },
  },
  methods: {
    ...mapActions("brands", ["searchBrands"]),
    ...mapActions('newspaper', ['postEditBulkyNewspaper']),
    onSubmitData() {
    
      const payload = this.getData.map((item, index) => {
          return {
          brand_id: item.id,  
          name: item.name,   
          start_date: moment(this.getStartDate[index]).format('YYYY-MM-DD'), 
          end_date: moment(this.getEndDate[index]).format('YYYY-MM-DD'),
          printed_price: this.getPrice[index],
          printed_currency_code: this.getCurrency[index]
        };
      })
     this.postEditBulkyNewspaper(payload)
    },

    deleteRow(index) {
      this.selectOnDataTable.splice(index, 1);
    },

    addNewRow() {
      const newRow = {
        name: '',
        offer_code: '',
        price_idr: '',
        price_usd: '',
      };
      
      this.selectOnDataTable.push(newRow);
    },
    handleCancel() {
    this.$emit('update:nextSection', !this.nextSection);
    },
    brandFind(query) {
      this.isBrandSearch = true;
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        let payload = {
          name: query,
          type: constant.ITEM_TYPES.NEWSPAPER.toLowerCase(),
          limit: 10,
        };
        this.searchBrands(payload).then((response) => {
          this.brandSearchOptions = response.data.data.rows;
          this.isBrandSearch = false;
        }).catch(() => {
          this.isBrandSearch = false;
        });
      }, 600);
    },
  }
}
</script>

<style scoped>
table {
  width: 100%;
}
.border-custom {
    border: 1px solid #3b82f6;
}
thead th {
  text-align: left;
  background-color: #f3f4f6;
  padding: 12px 8px;
}

tbody td {
  padding: 8px;
}

button {
  display: flex;
  align-items: center;
  justify-content: center;
}

button i {
  font-size: 1rem;
}
</style>
